<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-content>
                <div class="dialog-header">
                    <div class="dialog-title">Danh mục {{title}}</div>
                    <div class="dialog-close" @click="showDialog = false">
                        Close
                    </div>
                </div>
                <div class="dialog-search">
                    <md-field>
                        <md-input v-model="keyword" placeholder="Tìm nhanh"></md-input>
                        <md-icon>search</md-icon>
                    </md-field>
                </div>
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">#</th>
                                        <th style="width: 350px;">Mã {{title}}</th>
                                        <th style="width: 400px;">Tên {{title}}</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody> 
                                <tbody v-if="loadding == false && data.length > 0">
                                    <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td><a @click="selected(item)">{{item.code}}</a></td>
                                        <td><a @click="selected(item)">{{item.name}}</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </md-content>
        </md-dialog>
    </div>
</template>

<script>
    import typeService from '../../api/typeService'; 
    import messageBox from '../../utils/messageBox';

    export default {
        props: { 
            title: {
                type: String,
                required: true
            },
            group: {
                type: Number,
                required: true
            }
        },
        data: () => ({
            showDialog: false,
            data: [],
            loadding: false,
            keyword: ''
        }),

        created() {
        },

        methods: { 
            selected(unit){
                this.$emit('close', unit);
            },

            open(){
                this.getData();
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            refresh() {
                this.keyword = '';
                this.getData();
            },

            getData(){
                this.loadding = true;
                typeService.getTypes(this.group, this.keyword).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data; 
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; }); 
            },
        },
        watch: { 
            keyword: function () {
                this.getData(); 
            } 
        }, 
    }
</script>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        width: 800px;
        height: 455px;
        max-width: 800px;
        max-height: 455px;
    }
    .dialog-content {
        height: 345px;
    }
    .dialog-content table{
        height: 340px;
        min-height: 340px !important;
    }
</style>