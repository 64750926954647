<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-content>
                <div class="dialog-header">
                    <div class="dialog-title">Danh sách đề nghị sửa chữa</div>
                    <div class="dialog-close" @click="showDialog = false">
                        Close
                    </div>
                </div>
                <div class="dialog-search">
                    <md-field>
                        <md-input v-model="search.code" placeholder="Tìm theo mã"></md-input>
                        <md-icon>search</md-icon>
                    </md-field>
                    <md-field>
                        <md-input v-model="search.name" placeholder="Tìm theo tên"></md-input>
                        <md-icon>search</md-icon>
                    </md-field>
                </div>
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">#</th>
                                        <th style="width: 350px;">Số đề nghị</th>
                                        <th style="width: 400px;">Phương tiện/thiết bị</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody> 
                                <tbody v-if="loadding == false && pager.totalItems > 0">
                                    <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td><a @click="selected(item)">{{item.requestCode}}</a></td>
                                        <td>{{item.vehicle.licensePlate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="dialog-footer" v-if="pager.totalPages > 0">
                    <div class="dialog-paging">
                        <div v-if="pager.totalPages > 0">
                            <v-pagination v-model="search.pageIndex"
                            :page-count="pager.totalPages"
                            @change="onChange"></v-pagination>
                        </div>
                    </div>
                    <div>|</div>
                    <div v-if="pager.totalPages > 0" class="dialog-data-info">Xem <span>{{pager.startIndex}}-{{pager.endIndex}}/{{pager.totalItems}}</span> bản ghi</div>
                    <div>|</div>
                    <div class="dialog-paging-row-of-page">Rows/page: <a v-for="item in rowPerPageOptions" :key="item.id" :class="{'current-row-of-page': search.pageSize == item.id}" @click="changeRowPerPage(item.id)">{{item.text}}</a></div>
                    <div>|</div>
                    <div class="dialog-refresh"><a @click="refresh()">Làm tươi</a></div>
                </div>
            </md-content>
        </md-dialog>
    </div>
</template>

<script>
    import requestRepairService from '../../api/requestRepairService'; 
    import messageBox from '../../utils/messageBox'; 
    import common from '../../mixins';
    import vPagination from '../Pagination.vue';

    export default {
        components: {
            vPagination
        },
        data: () => ({
            showDialog: false,
            ids: [],
            data: [],
            pager: { totalPages: 1 }, 
            loadding: false, 
            search: { pageIndex: 1, pageSize: common.pageSize, code: '', name: '' },
            rowPerPageOptions: common.rowPerPagePopupOptions,
        }),

        created() {
        },

        methods: { 
            selected(item){
                this.$emit('close', item);
            },

            onChange: function () {
                this.getData();
            },

            open(){
                this.getData();
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            changeRowPerPage(pageSize){
                this.search = { pageIndex: 1, pageSize: pageSize, code: '', name: '' };
                this.getData();
            },

            refresh() {
                this.search = { pageIndex: 1, pageSize: common.pageSize, code: '', name: '' };
                this.getData();
            },

            getData(){
                this.loadding = true;
                requestRepairService.getRequests(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items; 
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; }); 
            },
        },
        watch: { 
            'search.pageIndex': function () { 
                this.getData(); 
            }, 
            'search.pageSize': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.code': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.name': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            } 
        }, 
    }
</script>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        width: 800px;
        height: 455px;
        max-width: 800px;
        max-height: 455px;
    }
    .dialog-content {
        height: 345px;
    }
    .dialog-content table{
        height: 340px;
        min-height: 340px !important;
    }
</style>